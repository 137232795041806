<template>
    <div>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">
            <div class="top_sort">
                <div class="auth" v-for="(v,i) of allFurnitureGlobalAttrs" :key="i">
                    <span>{{v.attrName}}</span>
                    <el-button type="text" :class="globalAttrOption[i]==null?'select':''" v-if="i==0" @click="setGlobalAttrOption(v,i,null,null)">全部</el-button>
                    <el-button :type="globalAttrOption[i]==null?'primary':'info'" plain size="mini" v-else @click="setGlobalAttrOption(v,i,null,null)">全部</el-button>
                    <template v-for="(vv,ii) of v.furnitureGlobalAttrOption">
                        <el-button type="text" :class="globalAttrOption[i]==vv.id?'select':''" v-if="i==0" @click="setGlobalAttrOption(v,i,vv,ii)">{{vv.optionValue}}</el-button>
                        <el-button :type="globalAttrOption[i]==vv.id?'primary':'info'" plain size="mini" v-else @click="setGlobalAttrOption(v,i,vv,ii)">{{vv.optionValue}}</el-button>
                    </template>
                </div>
                <div class="auth">
                    <span>排序</span>
                    <el-button :type="QueryFurnitureLibrary.goodSort===0?'primary':'info'" plain size="mini" @click="setGoodSort(0)">销量</el-button>
                    <el-button :type="QueryFurnitureLibrary.goodSort==1?'primary':'info'" plain size="mini" @click="setGoodSort(1)">价格</el-button>
                    <el-button :type="QueryFurnitureLibrary.goodSort==2?'primary':'info'" plain size="mini" @click="setGoodSort(2)">时间</el-button>
                    <el-button :type="QueryFurnitureLibrary.goodSort==3?'primary':'info'" plain size="mini" @click="setGoodSort(3)">库存</el-button>
                    <el-button :type="QueryFurnitureLibrary.goodSort==4?'primary':'info'" plain size="mini" @click="setGoodSort(4)">浏览量</el-button>
                </div>
            </div>
            <div class="middle_middle">
                <div style="float:left;margin:12px 0 0 28px;">
                    <span>价格</span>
                    <el-input size="mini" v-model.number="QueryFurnitureLibrary.priceMin"></el-input>
                    <span>—</span>
                    <el-input size="mini" v-model.number="QueryFurnitureLibrary.priceMax"></el-input>
                    <el-button type="primary" size="mini" @click="search">确定</el-button>
                </div>
                <div class="wangge" @click="ChangeType(2)">
                    <img src="@/assets/icon/liebiao.png" class="hover" alt="" :style="type==2?'display:none':'display:block'">
                    <img src="@/assets/icon/liebiao_hover.png" alt="" :style="type==2?'display:block':'display:none'">
                    <span :style="type==2?'color:#2090ff':''">列表模式</span>
                </div>
                <div class="wangge" @click="ChangeType(1)">
                    <img src="@/assets/icon/wangge_hover.png" class="hover" alt="" :style="type==1?'display:none':'display:block'">
                    <img src="@/assets/icon/wangge.png" alt="" :style="type==1?'display:block':'display:none'">
                    <span :style="type==1?'color:#2090ff':''">网格模式</span>
                </div>
            </div>
            <div class="middle">
                <div class="grid" v-if="type==1">
                    <ul>
                        <li v-for="v in queryFurnitureLibraryObj.content" @click="$oucy.go('/product?spuId='+v.id)" class="pointer" :key="v.id">
                            
                            <img :src="$oucy.ossUrl+v.spuImgs[0].imgUrl" v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl" class="spuImgs">
                            <el-row>
                                <el-col :span="24">
                                    <p class="title line-2" style="height:42px" v-html="v.spuName"></p>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                  <span class="price" v-if="v.spuMinPrise*1!=0">
                                    ￥{{v.spuMinPrise}}
                                  </span>
                                  <span class="price" v-else>面议</span>
                                </el-col>
                                <el-col :span="12"><span class="stock" style="float: right">库存&nbsp{{v.quantity}}</span></el-col>
                            </el-row>
                            <el-row>
                                <div class="brand_name df-ai-c">
                                    <el-image                                                             
                                         :src="$oucy.ossUrl + v.enterpriseAvata"
                                         class="enterpriseAvata"
                                         fit="cover"></el-image>
                                    <span style="line-height: 15px;" >{{v.enterpriseShopName}}</span>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="add">
                                    <el-button type="text" @click.stop="addStock(v)">加入进货车</el-button>
                                </div>
<!--                              <div class="sputime">-->
<!--                                <el-tag type="success" size="mini">发布日期：{{v.spuCreatetime}}</el-tag>-->
<!--                              </div>-->
                            </el-row>
                        </li>
                    </ul>
                </div>
                <div class="liebiao" v-if="type==2">
                    <ul>
                        <li v-for="v in queryFurnitureLibraryObj.content" @click="$oucy.go('/product?spuId='+v.id)" class="pointer" :key="v.id">
                            <table>
                                <tr>
                                    <td width="180px">
                                        <img :src="$oucy.ossUrl+v.spuImgs[0].imgUrl" v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl" style="width: 96px">
                                    </td>
                                    <td width="600px">
                                        <p class="title line-2" v-html="v.spuName"></p>
                                        <!--                           <div class="brand_name">
                                                                <span>海绵款</span>
                                                                <span>棕白</span>
                                                                <span>2.55米</span>
                                                                <span>送藤板</span>
                                                            </div> -->
                                    </td>
                                    <td width="320px">
                                        <!-- <span class="brand_name" style="line-height: 15px;">凯越家具</span> -->
                                    </td>
                                    <td width="280px">
                                        <span class="price" v-if="v.spuMinPrise*1!=0">
                                          ￥{{v.spuMinPrise}}
                                        </span>
                                        <span class="price" v-else>面议</span>
                                    </td>
                                    <td width="220px">
                                        <span class="stock">库存&nbsp{{v.quantity}}</span>
                                    </td>
                                    <td width="300px">
                                        <el-button type="text">加入进货车</el-button>
<!--                                        <el-tag type="success" size="mini">发布日期：{{v.spuCreatetime}}</el-tag>-->
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pagination">
                <el-pagination v-if="queryFurnitureLibraryObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj.size" :total="queryFurnitureLibraryObj.totalElements" @size-change="sizeChange" @current-change="currentChange" :page-sizes="pageSizes" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { furnitureGlobalAttr, globalClassify, spu } from "@/service/index.js"
import {localSet, localGet, localDel} from "@/store/store";

export default {
    name: "Material",
    data() {
        return {
            type: 1,
            allGlobalClassifyList: [],
            allFurnitureGlobalAttrs: [],
            queryFurnitureLibraryObj: {},
            globalAttrOption: [],
            // 请求对象
            QueryFurnitureLibrary: {
                keyword:"",
                raw_query:null,
                category: 1,
                limit: 20,
                start: 0,
                globalAttrOption: null,
                globalClassId: null,
                goodSort: '',
                priceMax: null,
                priceMin: null,
                siteId:null,//站点
            },
            pageSizes: [20, 40, 100]

        }
    },
    watch: {
        $route: {
            handler(to, from) {
                this.QueryFurnitureLibrary.keyword = to.query.keyword
                this.QueryFurnitureLibrary.raw_query = to.query.raw_query
                this.QueryFurnitureLibrary.start = 0
                this.queryFurnitureLibraryObj={}
                this.search()
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.getAllGlobalClassify()
        this.getAllFurnitureGlobalAttrs()
        this.search()
        let siteId = localGet('siteId')
        if(siteId){
            this.QueryFurnitureLibrary.siteId=siteId
        }
    },
    methods: {

        ChangeType(num) {
            this.type = num;
        },
        // 查询全局分类树
        getAllGlobalClassify() {
            globalClassify.getAllGlobalClassify({ globalClassifyEnum: 1 }).then(res => {
                this.allGlobalClassifyList = res || []
                this.allGlobalClassifyList.unshift({ classifyName: "全部分类", id: null })
                this.$oucy.keyAs(this.allGlobalClassifyList, 'classifyName')

            })
        },
        // 查询所有的属性不分页
        getAllFurnitureGlobalAttrs() {
            furnitureGlobalAttr.getAllFurnitureGlobalAttrs({ category: 1 }).then(res => {
                this.allFurnitureGlobalAttrs = res
                if (res) {
                    this.globalAttrOption = []
                    for (let v of res) {
                        this.globalAttrOption.push(null)
                    }
                }
            })
        },
        search() {
            if (this.QueryFurnitureLibrary.priceMax) {

            } else {
                this.QueryFurnitureLibrary.priceMax = null
            }
            if (this.QueryFurnitureLibrary.priceMin) {

            } else {
                this.QueryFurnitureLibrary.priceMin = null
            }
            this.$oucy.setTitle(`搜材料  ${this.QueryFurnitureLibrary.keyword}`)
            if(this.$oucy.isNull(this.QueryFurnitureLibrary.goodSort)){
                delete this.QueryFurnitureLibrary.goodSort
            }
            // this.QueryFurnitureLibrary.raw_query=this.QueryFurnitureLibrary.keyword
            spu.search(this.QueryFurnitureLibrary).then(res => {
                this.queryFurnitureLibraryObj = res||{}
            })
        },
        // 切换菜单回调
        menuSelect(v) {
            console.log(v)
            this.QueryFurnitureLibrary.globalClassId = v
            this.search()
        },
        // 排序
        setGoodSort(v) {
            this.QueryFurnitureLibrary.goodSort = v
            this.search()
        },
        // 属性
        setGlobalAttrOption(v, i, vv, ii) {
            if (vv) {
                this.$set(this.globalAttrOption, i, vv.id)
            } else {
                this.$set(this.globalAttrOption, i, null)
            }
            let globalAttrOption = []
            for (let v of this.globalAttrOption) {
                if (v) {
                    globalAttrOption.push(v)
                }
            }
            this.QueryFurnitureLibrary.globalAttrOption = JSON.stringify(globalAttrOption)
            this.search()
        },
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.search();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.search();
        },

    }
}
</script>
<style scoped>
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    min-height: 500px;
    padding: 30px 0;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.auth {
    display: flex;
    margin-bottom: 30px;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
}

.auth .el-button--text.select {
    color: #409EFF;
}

.el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
}

.el-button--mini {
    border: none;
}

.el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.middle_middle {
    height: 52px;
    background-color: #FFFFFF;
    margin: 10px 0 10px 10px;
}

.middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
}

.middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
}

.list>>>.el-table__row>td {
    /*去除表格线*/
    border: none;
}

.list>>>.el-table th.is-leaf {
    /*去除上边框*/
    border: none;
}

.list>>>.el-table::before {
    /*去除下边框*/
    height: 0;
}

.wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.middle {
    background: #FFFFFF;
    margin-left: 10px;
    margin-top: -15px;
}

.middle ul li:nth-child(4n) {
    margin-right: 0px;
}

.middle ul {
    text-align: left;
}

.middle ul li {
    width: 234px;
    height: 340px;
    background-color: #FFFFFF;
    padding: 10px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
}

.title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    /*font-weight: bold;*/
    color: #666666;
    margin-top: 10px;
    height: 36px;
}

.brand_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    float: left;
    margin-right: 5px;
}

.price {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E92121;
    line-height: 40px;
}

.stock {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 50px;
}

.add .el-button--text {
    width: 100%;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    margin-top: 15px;
    line-height: 0px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}

.liebiao {
    min-height: 500px;
}

.liebiao ul li {
    width: 1220px;
    height: 100px;
    background-color: #FFFFFF;
    list-style: none;
    /*margin-left: -20px;*/
}

table {
    text-align: left;
    margin-top: 15px;
}

.liebiao .el-button--text {
    width: 90px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    line-height: 0px;
}

.grid {
    min-height: 500px;
}

.grid ul {
    /*margin-left: -25px;*/

}

.el-page-header__title {
    background-color: red;
}

.wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.brand_name {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}

/*/deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .el-pager{position: relative;top: 9px;}
/deep/ .number{background: #FFFFFF!important;}
/deep/ .active{background:#2090ff!important ;}*/
.classifyIcon {
    width: 15px;
    height: 15px;
}

.spuImgs {
    width: 234px;
    height: 234px;
    background-color: #f1f1f1;
  border: 1px solid #eaeaea;
    
}

.spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
  border: 1px solid #eaeaea;
    
}
.enterpriseAvata{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;

   }
.sputime{
  margin-top: 10px;
  text-align: center;
}
</style>